import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from "gatsby";
import { EuiButton, EuiSpacer } from "@elastic/eui";
import Flyout from "components/Software/Windows/InstarVision/System_Requirements/sys-req_flyout";
import Table01 from "components/Software/Windows/InstarVision/Table01";
import ImageCard01 from "components/Software/Windows/InstarVision/instarVisionHorizontalCard01";
import ImageCard02 from "components/Software/Windows/InstarVision/instarVisionHorizontalCard02";
import ImageCard03 from "components/Software/Windows/InstarVision/instarVisionHorizontalCard03";
import MenuCard01 from "components/Software/Windows/InstarVision/instarVisionMenuCards01";
import MenuCard02 from "components/Software/Windows/InstarVision/instarVisionMenuCards02";
import MenuCard03 from "components/Software/Windows/InstarVision/instarVisionMenuCards03";
import NavButtons from "components/Software/Windows/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.",
  "image": "./P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <SEOHelmet title="InstarVision v3 for Windows" dateChanged="2018-01-25" author="Mike Polinowski" tag="INSTAR IP Camera" description="The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software." image="/images/Search/P_SearchThumb_InstarVision_Windows.png" twitter="/images/Search/P_SearchThumb_InstarVision_Windows.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Software/Windows/InstarVision/" locationFR="/fr/Software/Windows/InstarVision/" crumbLabel="InstarVision v3" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h3 {...{
      "id": "instarvision-v3-for-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#instarvision-v3-for-windows",
        "aria-label": "instarvision v3 for windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision v3 for Windows`}</h3>
    <p><strong parentName="p">{`The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.`}</strong></p>
    <p>{`The InstarVision Surveillance Center allows you to keep an eye on your home from anywhere, turning your PC into a video surveillance system, remote controlling your IP camera via pan & tilt zoom function. This webcam software can also broadcast the cameras live RTSP video stream to the internet or integrate it into your website with via video plugins or as a auto-refreshing JPG stream. The advanced video motion detection algorithm can be set up to notify you by Email alerts and record video in case of an alarm event. Other features include a multi-level user management, an electronic interactive map displaying your cameras on a site plan and stand-alone multi-channel video player to quickly screen your video recordings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d79923f0ba5452632bbe4858a5aa4df3/ad00e/Image002.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACnklEQVQozy3Ly1MScQAA4F8WoLi7wL4XVpaXBKK4Kj4ALVPzBY6JpoIoIT4oBTPUhFIkBXW0DjWhZs6UJ83p4slrHvPsX5KSMx62S9/9Aw32OpbAWIpkGZJT0gxFsBSpoiitWkWSBIahFI7ROKYkMZrAaJIokIgT8cWjo6Mfx8fAWmJERHf0NKZjKRaDcRlEKWCpNF8BE1KxSCrKw6ViDQ5zsgK6UExC+QCAF8/Dvy8uLi8vgZ0vJfPvsiikxBUmjtFyWkLJoBwuk0KoDCFkCEdqKowVlRpWR6EkAgMAUsnkn6ur29tb4HTUQRIpiuE6ndrX2xXw+628rYjjcBlSIBETUL6pqFTJWFz2ErejTIXK7wGwvpYWBOH6+grYykskojy5HDHq1dupxFYqYdOzxQyqVaESiYSGxBYGNquJ6sqqxhqewmQAgPVMRhCEXC4HnA/cNFuqN1SV840ul7fT5bOYbNbSasN9Xq5AGRSmMDmGY5yGoymSJHEAwMbmpiAIf3M50Du81fn040DgYGjimzf0dXhyfyJy4AvtNHdFWCWh07BGg6620lpmMek1RbZys1mn/LKb/Z+9w4lQMJGYfvsyvBKZTi+9+eQdXY5OLT7u9MuRQqejtueJu6vJ4WmxP6qv4cvMBjWzv/NZEISbmxsQiibDM+mNV4vJ2XfBqfeZ2Jp3MBGJLvd7PAgMFRuLO9pbu10tfe626Ji33GqmVNRCfP781/np6SmwOdrrG/pWY/GBwPbQ2IfXM+nx8fUmz0qLewRXwLV2fnbGFwp2a4xFPa1V1by5ocMZjkwefj/cyWZBny8ce+YfDWdmFvaW4tnttf3U8m4gutcfjLW3ta7GwtGxwaGRgUH/QPNDZ2B4MDIfmZufOzs7+3ly8g+UPMFbHMSfGQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d79923f0ba5452632bbe4858a5aa4df3/e4706/Image002.avif 230w", "/en/static/d79923f0ba5452632bbe4858a5aa4df3/d1af7/Image002.avif 460w", "/en/static/d79923f0ba5452632bbe4858a5aa4df3/7f308/Image002.avif 920w", "/en/static/d79923f0ba5452632bbe4858a5aa4df3/148ae/Image002.avif 1366w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d79923f0ba5452632bbe4858a5aa4df3/a0b58/Image002.webp 230w", "/en/static/d79923f0ba5452632bbe4858a5aa4df3/bc10c/Image002.webp 460w", "/en/static/d79923f0ba5452632bbe4858a5aa4df3/966d8/Image002.webp 920w", "/en/static/d79923f0ba5452632bbe4858a5aa4df3/ec897/Image002.webp 1366w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d79923f0ba5452632bbe4858a5aa4df3/81c8e/Image002.png 230w", "/en/static/d79923f0ba5452632bbe4858a5aa4df3/08a84/Image002.png 460w", "/en/static/d79923f0ba5452632bbe4858a5aa4df3/c0255/Image002.png 920w", "/en/static/d79923f0ba5452632bbe4858a5aa4df3/ad00e/Image002.png 1366w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d79923f0ba5452632bbe4858a5aa4df3/c0255/Image002.png",
              "alt": "InstarVision v3 für Windows",
              "title": "InstarVision v3 für Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h2>
    <p>{`When you start the InstarVision Surveillance Center, you will be greeted by the main graphical user interface (GUI). The left area of the UI is the video canvas region. On the right you find the side navigation. You can hide or show the navigation at any time to maximize the space available for your cameras. Maximize the GUI to fit your screen, hide the program to windows tray, or arbitrarily change the GUI size via dragging with your mouse. Click on Exit to stop the program and get back to the Windows desktop.`}</p>
    <ul>
      <li parentName="ul">{`To add cameras to the software open the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Camera_List/"
        }}>{`Camera List`}</a>{` and click on the + icon in the lower right of the list area.`}</li>
      <li parentName="ul">{`The software offers 3 recording methods. You can directly click the camera icon in the top right of each `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Camera_List/"
        }}>{`Video Channel Viewport`}</a>{` to start or stop a manual recording. The `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Record/Schedule/"
        }}>{`Scheduled Recording`}</a>{` and `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Record/Alarm/"
        }}>{`Alarm Recording`}</a>{` is located in the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Record/"
        }}>{`Record Menu`}</a>{`.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Record/Email/"
        }}>{`Email Notification`}</a>{` and `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Record/FTP/"
        }}>{`FTP Upload`}</a>{` in case of an alert is also located in the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Record/"
        }}>{`Record Menu`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "system-requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-requirements",
        "aria-label": "system requirements permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Requirements`}</h2>
    <p><strong parentName="p">{`Server Setup`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CPU`}</strong>{`: Intel Core i5-2520M 2.50GHz`}</li>
      <li parentName="ul"><strong parentName="li">{`RAM`}</strong>{`: 4 GB`}</li>
      <li parentName="ul"><strong parentName="li">{`DISK`}</strong>{`: 120 GB SSD`}</li>
      <li parentName="ul"><strong parentName="li">{`OS`}</strong>{`: Windows 10 64-Bit`}</li>
    </ul>
    <p><em parentName="p">{`(All cameras were connected by Ethernet cable.)`}</em></p>
    <p><em parentName="p">{`As bare minimum requirements for 4 HD cameras we recommend`}</em>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`CPU`}</strong>{`: DualCore ASUS eeePC (Older Generation)`}</li>
      <li parentName="ul"><strong parentName="li">{`RAM`}</strong>{`: 2 GB`}</li>
      <li parentName="ul"><strong parentName="li">{`DISK`}</strong>{`: 250 GB HDD`}</li>
      <li parentName="ul"><strong parentName="li">{`OS`}</strong>{`: Windows 7 32-Bit`}</li>
    </ul>
    <Flyout mdxType="Flyout" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "video-mode-default-login-and-language",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#video-mode-default-login-and-language",
        "aria-label": "video mode default login and language permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Video Mode, Default Login and Language`}</h2>
    <p><strong parentName="p">{`IF YOUR CAMERA´S VIDEO STAYS BLACK`}</strong>{` even though you are able to record videos and take snapshots, there might be a problem with the video encoding on your PC graphic card. Please open the `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/System/"
      }}>{`System Menu`}</a>{` and switch the Yuy2 Video Encoding. The video of your camera should become visible after you restarted the software.`}</p>
    <EuiSpacer mdxType="EuiSpacer" /> 
    <ImageCard01 mdxType="ImageCard01" />
    <EuiSpacer size="xxl" mdxType="EuiSpacer" />
    <Table01 mdxType="Table01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`To change the `}<strong parentName="p">{`LANGUAGE`}</strong>{` of the user interface, please go to the side navigation and open the `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/System/"
      }}>{`System Menu`}</a>{`. Now choose your language from the drop down menu.`}</p>
    <h2 {...{
      "id": "registration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#registration",
        "aria-label": "registration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Registration`}</h2>
    <p><strong parentName="p">{`When you first start the Surveillance Center you will see the following registration dialog:`}</strong></p>
    <p>{`You can click "Trial Version" button to test the software for 14 days for free - the software will be fully functional but limited to 16 camera channels! After you purchased the software and received your license code, please input the code here, and click Register Online. Alternatively, use the Email function to register your software by an ID token - Click on Create ID File and send that file to us by `}<a href="www.instar.de/contact_us/?___store=de_english" target="_blank" rel="noopener noreferrer">{`Email`}</a>{`.`}</p>
    <EuiSpacer size="xxl" mdxType="EuiSpacer" />
    <ImageCard02 mdxType="ImageCard02" />
    <EuiSpacer size="xl" mdxType="EuiSpacer" />
    <h2 {...{
      "id": "side-navigation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation",
        "aria-label": "side navigation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation`}</h2>
    <p><strong parentName="p">{`The Side Navigation Bar gives you quick access to all the software´s functions. There are 10 sub-categories you can click to display corresponding settings:`}</strong></p>
    <EuiSpacer size="xxl" mdxType="EuiSpacer" />
    <ImageCard03 mdxType="ImageCard03" />
    <EuiSpacer size="xxl" mdxType="EuiSpacer" />
    <h2 {...{
      "id": "user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#user-interface",
        "aria-label": "user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`User Interface`}</h2>
    <MenuCard01 mdxType="MenuCard01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <MenuCard02 mdxType="MenuCard02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <MenuCard03 mdxType="MenuCard03" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      